import React from 'react'
import styled from 'styled-components'
import useLanguage from '../../../hooks/useLanguage'
import Text from '../../common/Text'
import { Carousel } from '@trendyol-js/react-carousel';
import Item from './components/Item';

const Laboratory = () => {

    const { data } = useLanguage()

    if (!data) {
        return <div/>
    }

    const titleArray = data.laboratory.title.split(" ")
    const title = titleArray.slice(1, titleArray.length).join(" ")
    const firstWord = titleArray.slice(0,1)

    return (
        <Content id="laboratory">
            <Title>
                <Text id="title1" fontSize={"42px"} color={"#8053FF"} fontWeight={"bold"}>{ firstWord }</Text>
                <Text id="title2" fontSize={"42px"} color={"#23155B"} fontWeight={"bold"}>{ title }</Text>
            </Title>
            <Slider>
                {/* https://trendyol.github.io/react-carousel/docs/infinity */}
                <Carousel transition={ 1} autoSwipe={true}  show={4} infinite swiping={true}>
                    <Item />
                    <Item />
                    <Item />
                    <Item />
                    <Item/>
                </Carousel>
            </Slider>
        </Content>
    )
}

export default Laboratory

const Content = styled.section`
    position: relative;
    height: auto;
    width: 100%;
    background-color: white;
    z-index: 2;
    width: calc(100% - 400px);
    padding: 0 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`

const Title = styled.div`
    display: flex;
    gap: 8px;
    @media (max-width: 768px) {
        flex-direction: column;
        text-align: center;
        #title1, #title2{
            font-size: 36px;
        }
    }
`

const Slider = styled.div`
    width: 100%;
    margin-top: 5rem;
    @media (max-width: 768px) {
        display: none;
    }
`