import { Button, IconButton } from '@mui/material'
import React, { useState } from 'react'
import styled from 'styled-components'
import useLanguage from '../../../../hooks/useLanguage'
import MenuIcon from '@mui/icons-material/Menu';

const ListMenu = () => {

    const { data } = useLanguage()
    const [menu, setMenu] = useState(false)

    const handleMenu = () => {
        setMenu(!menu)
    }

    if (!data) {
        return <div />
    }

    return (
        <>
            <ButtonMenu>
                <IconButton onClick={handleMenu}>
                    <MenuIcon/>
                </IconButton>
            </ButtonMenu>
            <ListContent menu={menu}>
                <LinkMenu href='#investigation'>{data.navbar.buttons.investigation}</LinkMenu>
                <LinkMenu href='#laboratory'>{data.navbar.buttons.laboratory}</LinkMenu>
                <LinkMenu href='#about'>{data.navbar.buttons.courses}</LinkMenu>
                <LinkMenu href='#press'>{data.navbar.buttons.press}</LinkMenu>
                <LinkMenu href='#about'>{data.navbar.buttons.contact}</LinkMenu>
                <Button color='primary' variant='contained'>{data.navbar.buttons.single}</Button>
            </ListContent>
        </>
    )
}

export default ListMenu

const ListContent = styled.div`
    display: flex;
    gap: 20px;
    align-items: center;
    @media (max-width: 768px) {
        /* display: none; */
        width: 100%;
        flex-direction: column;
        position: absolute;
        left: 0;
        top: ${p => !!p.menu ? '80px' : '-500px'};
        background-color: #fff;
        padding-bottom: 1rem;
        transition: ease-in-out all .3s;
    }
`

const ButtonMenu = styled.div`
    display: none;
    @media (max-width: 768px) {
        display: flex;
        margin-right: 3rem;
        /* position: absolute; */
    }
`

const LinkMenu = styled.a`
    text-decoration: none;
    color: #23155B;
    font-weight: 500;
    font-size: 14px;
`