import { createTheme } from '@mui/material/styles';

export const theme = createTheme({
    palette: {
        primary: {
            main: "#8053FF",
        },
        secondary: {
            main: "#23155B",
        },
    },
    components: {
        // Name of the component
        MuiButton: {
            styleOverrides: {
                root: {
                    fontSize: '14px',
                    textTransform: 'none',
                    borderRadius: '8px',
                    boxShadow: 'none'
                },
            },
        },
    },
});
