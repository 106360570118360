import React from "react";
import styled from "styled-components";
import Text from "../../common/Text";
import ParticlesLayout from "../../common/Particles";
import useLanguage from "../../../hooks/useLanguage";
import { Button } from "@mui/material";
import EditButton from "../../common/EditButton";
import EditSection from "../../common/EditSection";

const Banner = (props) => {

    const { isEdit } = props

    const { data } = useLanguage()

    if (!data) {
        return <div/>
    }

    return <BannerContent id="home">
        <Data>
            <EditSection>
                <Text id="title" fontSize={'42px'} fontWeight={'700'} color={"#8053FF"}>{ !!data && data.banner.title }</Text>
                {isEdit && <EditButton route={"banner.title"} label="Titulo del banner" value={data.banner.title} />}
            </EditSection>
            <EditSection>
                <Text>{!!data && data.banner.subtitle}</Text>
                {isEdit && <EditButton route={"banner.subtitle"} label="Subtitulo del banner" value={data.banner.subtitle} />}
            </EditSection>
            <Buttons>
                <Button variant="contained" color="primary">{data.banner.buttons.firts}</Button>
                <Button variant="outlined" color="primary">{data.banner.buttons.second}</Button>
            </Buttons>
        </Data>
        {!isEdit && <ParticlesLayout />}
    </BannerContent>;
};

export default Banner;

const BannerContent = styled.div`
    margin-top: 100px;
    width: 100%;
    height: 300px;
    background: #fbfaff;
    display: flex;
    flex-direction: column;
    padding-top: 150px;
    align-items: center;
    position: relative;
    @media (max-width: 768px) {
        height: auto;
        padding-top: 50px;
        text-align: center;
        padding-bottom: 50px;
        #title{
            font-size: 36px;
        }
    }
`;

const Data = styled.div`
    display: flex;
    flex-direction: column;
    z-index: 2;
    align-items: center;
`

const Buttons = styled.div`
    display: flex;
    gap: 6px;
    margin-top: 2.5rem;
    @media (max-width: 768px) {
        flex-direction: column;
    }
`