import axios from 'axios';
import { createContext, useState, useEffect } from 'react';
import useFirestore from '../hooks/useFirestore';

const ENDPOINT = window.location.origin + '/language'

export const Language = createContext();

export const LanguageProvider = (props) => {

    const { children } = props;

    const [page, setPage] = useState(null)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const { getData, updateData } = useFirestore()

    useEffect(() => {
        !page && handleLanguage(localStorage.getItem('language') || 'es')
    }, [page])
    
    // FUNCTIONS
    const handleLanguage = async (lan) => {
        setLoading(true)
        try {
            const searchLan = lan === 'es' ? 'español' : 'ingles'
            const req = await getData('language')
            const languages = req.docs.map(item => ({ lanName: item.id, ...item.data()}))
            const currentLang = languages.find(lan => lan.lanName === searchLan)
            setPage(currentLang)
            localStorage.setItem('language', lan)
        } catch (error) {
            setError(true)
        } finally {
            setLoading(false)
        }
    }

    const handleSetItemLanguage = async (value, route) => {
        setLoading(true)
        const keysProps = route.split(".").map(item => (`["${item}"]`)).join("")
        let pageInfo = {...page}
        const expression = `pageInfo${keysProps}='${value}'`
        eval(expression)
        setPage({ ...pageInfo })
        const searchLan = localStorage.getItem('language') === 'es' ? 'español' : 'ingles'
        await updateData('language', searchLan, { ...pageInfo })
        setLoading(false)
    }

    return <Language.Provider value={{currentLan: localStorage.getItem('language'), data: page, loading, error, handleLanguage, handleSetItemLanguage}}>
        {children}
    </Language.Provider>
    
}