import { Button, CircularProgress, Dialog, IconButton, TextField } from '@mui/material'
import React, { useState } from 'react'
import EditNoteIcon from '@mui/icons-material/EditNote';
import styled from 'styled-components';
import CloseIcon from '@mui/icons-material/Close';
import Text from './Text';
import AddIcon from '@mui/icons-material/Add';
import useLanguage from '../../hooks/useLanguage';

const EditListButton = (props) => {

    const {
        color = 'primary',
        title = 'Titulo',
        array = [],
        route = ''
    } = props

    const [open, setOpen] = useState(false)
    const [itemSelect, setItemSelect] = useState(null)
    const [type, setType] = useState(false)
    const { handleSetItemLanguage, loading } = useLanguage()

    const handleClose = () => setOpen(false)
    const handleOpen = () => {
        handleAdd()
        setOpen(true)
    }

    const handleSelect = (item) => {
        setItemSelect(item)
        setType('Edit')
    }

    const handleAdd = () => {
        setItemSelect({
            title: '',
            description: '',
            link_image: '',
            link_url: ''
        })
        setType('Add')
    }

    const handleDelete = (itemParam) => {
        const result = array.filter(item => item.title !== itemParam.title)
        handleSetItemLanguage(JSON.stringify(result), route)
    }

    const handleSubmit = () => {
        const itemNew = sanitizerItem(itemSelect)
        if (type === 'Add') {
            const result = [...array, itemNew]
            handleSetItemLanguage(JSON.stringify(result), route)
        }
        if (type === 'Edit') {
            const result = array.map((item, index) => {
                if (index === itemSelect.id) {
                    return {
                        title: itemNew.title,
                        description: itemNew.description,
                        link_image: itemNew.link_image,
                        link_url: itemNew.link_url
                    }
                }
                return item
            })
            // console.log('result', result)
            handleSetItemLanguage(JSON.stringify(result), route)
        }
        setItemSelect(null)
    }

    const handleChange = (e) => {
        setItemSelect({ ...itemSelect, [e.target.name]: e.target.value })
    }

    const sanitizerItem = value => {
        return {
            id: value?.id || false,
            title: value.title.replace(/"/g, '”'),
            description: value.description.replace(/"/g, '”'),
            link_image: value.link_image.replace(/"/g, '”'),
            link_url: value.link_url.replace(/"/g, '”')
        }
    }

    return (
        <>
            <IconButton onClick={handleOpen}><EditNoteIcon color={color} /></IconButton>
            <Dialog onClose={handleClose} open={open} fullScreen>
                {loading ? <ContentLoading><CircularProgress /></ContentLoading> : (<>
                    <CloseButton>
                        <IconButton onClick={handleClose}>
                            <CloseIcon color="primary" />
                        </IconButton>
                    </CloseButton>
                    <ContentDialog>
                        <AreaGrid>
                            <Text color={"#8053FF"} fontSize={"24px"}>{title}</Text>
                            <List>
                                {array.map((card, index) => <CardItem {...card} id={index} handleSelect={handleSelect} handleDelete={handleDelete} />)}
                                <AddItem onClick={handleAdd}>
                                    <AddIcon color='primary' />
                                    <Text color={"#8053FF"}>Agregar item</Text>
                                </AddItem>
                            </List>
                        </AreaGrid>
                        {itemSelect && <AreaGrid>
                            <Form>
                                <Text color={"#8053FF"} fontSize={"24px"}>{type === 'Add' ? 'Agregar item' : 'Editar item'}</Text>
                                <TextField onChange={handleChange} name="title" value={itemSelect?.title} placeholder='Título' fullWidth multiline size='small' />
                                <TextField onChange={handleChange} name="description" value={itemSelect?.description} placeholder='Descripción' fullWidth multiline size='small' />
                                <TextField onChange={handleChange} name="link_image" value={itemSelect?.link_image} placeholder='Link de imagen' fullWidth multiline size='small' />
                                <TextField onChange={handleChange} name="link_url" value={itemSelect?.link_url} placeholder='Link de navegación' fullWidth multiline size='small' />
                                <Button
                                    variant='contained'
                                    color='primary'
                                    onClick={handleSubmit}
                                    disabled={!(itemSelect?.title && itemSelect?.description && itemSelect?.link_url && itemSelect?.link_image)}
                                >
                                    Guardar cambios
                                </Button>
                            </Form>
                        </AreaGrid>}
                    </ContentDialog>
                </>)}

            </Dialog>
        </>
    )
}

export default EditListButton

const ContentDialog = styled.div`
    width: 100%;
    height: 100%;
    background-color: #f1f1f1;
    display: grid;
    grid-template-columns: 2fr 1fr;
`

const CloseButton = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
`

const AreaGrid = styled.div`
    padding: 1rem;
    width: calc(100% - 2rem);
    height: 90vh;
`

const List = styled.div`
    margin-top: 1rem;
    display: flex;
    max-height: 80vh;
    overflow-y: auto;
    width: 100%;
    flex-direction: column;
    gap: 0px;
`

const AddItem = styled.div`
    width: calc(100% - 1rem - 4px);
    height: 50px;
    margin: 0.5rem;
    border: 2px dotted #8053FF;
    border-radius: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    box-shadow: 0px 8px 12px -6px rgba(35, 21, 91, 0.05), 0px 14px 64px -4px rgba(35, 21, 91, 0.05);
`

const Form = styled.div`
    margin-top: 3.5rem;
    display: flex;
    max-height: 70vh;
    overflow-y: auto;
    flex-direction: column;
    gap: 1rem;
    background-color: #fff;
    border-radius: 20px;
    padding: 1rem;
    box-shadow: 0px 8px 12px -6px rgba(35, 21, 91, 0.05), 0px 14px 64px -4px rgba(35, 21, 91, 0.05);
`

const ContentLoading = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`





const CardItem = (props) => {

    const { title, handleSelect, handleDelete } = props

    const onDelete = () => {
        const result = window.confirm("¿Eliminar?")
        result && handleDelete(props)
    }

    return <CardContent onClick={() => handleSelect(props)}>
        <CloseButton>
            <IconButton onClick={onDelete}>
                <CloseIcon color="primary" />
            </IconButton>
        </CloseButton>
        {title}
    </CardContent>
}

const CardContent = styled.div`
    width: calc(100% - 3rem);
    position: relative;
    cursor: pointer;
    height: auto;
    padding: 1rem;
    margin: 0.5rem;
    background-color: #fff;
    border-radius: 20px;
    box-shadow: 0px 8px 12px -6px rgba(35, 21, 91, 0.05), 0px 14px 64px -4px rgba(35, 21, 91, 0.05);
`