import React from 'react'
import styled from 'styled-components'
import LogoutIcon from '@mui/icons-material/Logout';
import { useNavigate } from 'react-router-dom';
import useAuth from "../../hooks/useAuth";

const DevTool = () => {

    const { handleLoginStorage } = useAuth()
    const navigate = useNavigate()

    const handleLogout = () => {
        handleLoginStorage(false)
        navigate('../')
    }

    return (
        <DevContent onClick={handleLogout}>
            <LogoutIcon/>
            Salir del modo edición
        </DevContent>
    )
}

export default DevTool

const DevContent = styled.div`
    cursor: pointer;
    position: fixed;
    display: flex;
    gap: 8px;
    left: 0;
    bottom: 1rem;
    padding: 0.6rem 1rem;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: #8053ff;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    z-index: 99;
    user-select: none;
`