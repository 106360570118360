import React from "react";
import styled from "styled-components";
import SwitchLan from "./components/SwitchLan";
import ListMenu from "./components/ListMenu";
import Logo from "../../../assets/images/Logo.svg"

const Navbar = () => {
    return <NavbarContent>
        <LogoLink href="#home">
            <img src={Logo} alt="Logo" width={150} />
        </LogoLink>
        <Buttons>
            <ListMenu />
            <SwitchLan />
        </Buttons>
    </NavbarContent>;
};

export default Navbar;

const NavbarContent = styled.nav`
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 400px);
    padding: 0 200px;
    height: 80px;
    background-color: white;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
    @media (max-width: 768px) {
        width: 100%;
        padding: 0 1rem;
        /* background-color: red; */
    }
`;

const Buttons = styled.div`
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
`

const LogoLink = styled.a`
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
`