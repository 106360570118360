import React from 'react'
import styled from 'styled-components'
import Text from '../../common/Text'
import CardCourse from './components/CardCourse'
import EditSection from '../../common/EditSection'
import EditListButton from '../../common/EditListButton'
import useLanguage from '../../../hooks/useLanguage'

const Courses = (props) => {

    const { isEdit } = props

    const { data } = useLanguage()

    if (!data) {
        return <div/>
    }

    const courses = JSON.parse(data.courses.listItems)

    return (
        <CoursesContent>
            <EditSection>
                <Text color={"#23155B"} fontSize={"42px"} fontWeight={"bold"}>{data.courses.title}</Text>
                {isEdit && <EditListButton 
                    title={"Edición de cursos"}
                    array={courses}
                    route={"courses.listItems"}
                />}
            </EditSection>
            <GridCards>
                {courses.map(item => <CardCourse {...item} />)}
            </GridCards>
        </CoursesContent>
    )
}

export default Courses

const CoursesContent = styled.div`
    position: relative;
    height: auto;
    width: calc(100% - 400px);
    padding: 2rem 200px;
    background-color: #f9f6ff;
    z-index: 2;
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    align-items: start;
    @media (max-width: 768px) {
        width: calc(100% - 2rem);
        grid-template-columns: 1fr;
        padding: 1rem;
        text-align: center;
    }
`

const GridCards = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: calc(100% - 4rem);
    max-height: calc(400px - 4rem);
    padding: 2rem;
    padding-top: 0;
    overflow-y: auto;
    @media (max-width: 768px) {
        text-align: start;
    }
`