import { collection, getDocs, updateDoc, doc } from '@firebase/firestore'
import { db } from '../services/firebase-config'

const useFirestore = () => {

    const getData = async (value) => {
        const collectionRef = collection(db, value)
        const res = await getDocs(collectionRef)
        return res
    }

    const updateData = async (value, document, data) => {
        const collectionRef = doc(db, value, document)
        const res = await updateDoc(collectionRef , data)
        return res
    }
    

    return { getData, updateData }
}

export default useFirestore