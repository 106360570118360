import React from 'react'
import styled from 'styled-components'
import Text from '../../../common/Text'

const CardPress = (props) => {

    const { link_image, title, description } = props

    return (
        <CardContent>
            <ImagesContent bg={link_image} />
            <Description>
                <Text fontSize={"18px"} color={"#23155B"}>{title}</Text>
                <Text fontStyle={"italic"} fontSize={"14px"} color={"#8053FF"}>{description}</Text>
            </Description>
        </CardContent>
    )
}

export default CardPress

const CardContent = styled.div`
    width: 100%;
    height: 400px;
    min-height: 400px;
    max-height: 400px;
    background: #fff;
    box-shadow: 0px 8px 12px -6px rgba(35, 21, 91, 0.05), 0px 14px 64px -4px rgba(35, 21, 91, 0.05);
    border-radius: 24px;
`

const ImagesContent = styled.div`
    height: 60%;
    background-color: #D9D9D9;
    background-image: url(${p => p.bg});
    background-position: center;
    background-repeat: round;
    background-size: 100%;
    border-top-right-radius: 24px;
    border-top-left-radius: 24px;
`

const Description = styled.div`
    height: 40%;
    padding: 1.5rem;
`