import React from "react";
import Navbar from "../components/landing/navbar/Navbar";
import Banner from "../components/landing/banner/Banner";
import useAuth from "../hooks/useAuth";
import DevTool from "../components/custom/DevTool";
import Investigation from "../components/landing/investigation/Investigation";
import Laboratory from "../components/landing/laboratory/Laboratory";
import JoinInvestigation from "../components/landing/join/JoinInvestigation";
import Courses from "../components/landing/courses/Courses";
import Press from "../components/landing/press/Press";

const Custom = () => {

    const { isLogin } = useAuth()

    if (Boolean(isLogin) === false) {
        window.open('../admin', '_self')
    }

    return (
        <> 
            <DevTool />
            <Navbar />
            <Banner isEdit={true} />
            <Investigation isEdit={true}/>
            <Laboratory isEdit={true}/>
            <JoinInvestigation isEdit={true} />
            <Courses isEdit={true}/>
            <Press isEdit={true}/>
        </>
    );
};

export default Custom;
