// Import the functions you need from the SDKs you need

import { initializeApp } from "firebase/app";
import { getFirestore } from "@firebase/firestore";


const firebaseConfig = {
    apiKey: "AIzaSyDHtCip8r_RUlIs3J4EEAw1_o_VU-53n2M",
    authDomain: "vision-capital-c0573.firebaseapp.com",
    projectId: "vision-capital-c0573",
    storageBucket: "vision-capital-c0573.appspot.com",
    messagingSenderId: "598317799525",
    appId: "1:598317799525:web:d33094576e42b18062ce8c"
};


// Initialize Firebase

const app = initializeApp(firebaseConfig);
export const db = getFirestore(app)