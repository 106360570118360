import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import useLanguage from '../../../../hooks/useLanguage'

const SwitchLan = () => {

    const { handleLanguage, currentLan } = useLanguage()

    return (
        <ToggleButtonGroupFake
            color="primary"
            value={currentLan}
            exclusive
            onChange={() => handleLanguage(currentLan === 'es' ? 'en' : 'es')}
            aria-label="Language"
            size='small'
        >
            <ToggleButton value="es">ES</ToggleButton>
            <ToggleButton value="en">EN</ToggleButton>
        </ToggleButtonGroupFake>
    );

    // return (
    //     <Content>
    //         <Button disabled={currentLan === 'es'} onClick={() => handleLanguage('es')}>ES</Button>
    //         <Button disabled={currentLan === 'en'} onClick={() => handleLanguage('en')}>EN</Button>
    //     </Content>
    // )
}

export default SwitchLan

const ToggleButtonGroupFake = styled(ToggleButtonGroup)`
    position: fixed;
    bottom: 1rem;
    right: 1rem;
    background-color: white;
`