import React from 'react'
import styled from 'styled-components'
import Text from '../../common/Text'
import { Button } from '@mui/material'
import useLanguage from '../../../hooks/useLanguage'
import EditSection from '../../common/EditSection'
import EditButton from '../../common/EditButton'

const JoinInvestigation = (props) => {

    const { isEdit = false } = props

    const { data } = useLanguage()

    if (!data) {
        return <div />
    }

    return (
        <Content>
            <CardInformation>
                <Title>
                    <EditSection>
                        <Text id="title1" fontSize={"42px"} color={"#fff"} fontWeight={"bold"}>{data.joinInvestigation.title}</Text>
                        {isEdit && <EditButton color="basic" route={"joinInvestigation.title"} label="Titulo de la investigacion" value={data.joinInvestigation.title} />}
                    </EditSection>
                </Title>
                <EditSection>
                    <Information dangerouslySetInnerHTML={{ __html: data.joinInvestigation.description }} />
                    {isEdit && <EditButton color="basic" route={"joinInvestigation.description"} label="Descripcion de la investigacion" value={data.joinInvestigation.description} />}
                </EditSection>
                <br />
                <Button variant='contained' color='primary'>{data.joinInvestigation.button}</Button>
            </CardInformation>
        </Content>
    )
}

export default JoinInvestigation

const Content = styled.section`
    position: relative;
    height: auto;
    width: 100%;
    background-color: white;
    z-index: 2;
    width: calc(100% - 400px);
    padding: 5rem 200px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    @media (max-width: 768px) {
        width: calc(100% - 2rem);
        padding: 1rem;
        text-align: center;
        #title1 {
            font-size: 32px;
        }
    }
`

const CardInformation = styled.div`
    background: linear-gradient(82.78deg, #23155B 0%, #7B61FF 100%);
    border-radius: 8px;
    width: calc(100% - 6rem);
    min-height: 100px;
    padding: 3rem;
`

const Title = styled.div`
    display: flex;
    gap: 8px;
`

const Information = styled.div`
    color: #fff !important;
`