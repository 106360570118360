import React from 'react'
import styled from 'styled-components'
import Text from '../../common/Text'
import useLanguage from '../../../hooks/useLanguage'
import CardPress from './components/CardPress'
import EditSection from '../../common/EditSection'
import EditListButton from '../../common/EditListButton'

const Press = (props) => {

    const { isEdit } = props

    const { data } = useLanguage()

    if (!data) {
        return <div />
    }

    const press = JSON.parse(data.press.listPress)

    return (
        <PressContent id='press'>
            <EditSection>
                <Text color={"#23155B"} fontSize={"42px"} fontWeight={"bold"}>{data.press.title}</Text>
                {isEdit && <EditListButton
                    title={"Edición de prensa"}
                    array={press}
                    route={"press.listPress"}
                />}
            </EditSection>
            <Cards>
                {press.map(item => <CardPress {...item} />)}
            </Cards>
        </PressContent>
    )
}

export default Press

const PressContent = styled.div`
    position: relative;
    height: auto;
    width: 100%;
    background-color: white;
    padding: 2rem 0;
    z-index: 2;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`

const Cards = styled.div`
    margin: 1.5rem 0;
    display: grid;
    gap: 2rem;
    width: calc(100% - 400px);
    grid-template-columns: 1fr 1fr;
    @media (max-width: 768px) {
        width: calc(100% - 2rem);
        grid-template-columns: 1fr;
        padding: 1rem;
    }
`