import React from 'react'
import styled from 'styled-components'
import Text from '../../../common/Text'

const CardCourse = (props) => {

    const { title, description, link_url } = props

    return (
        <CardContent onClick={() => window.open(link_url)}>
            <Text color={"#8053FF"} fontSize={"18px"} fontWeight={"bold"}>{title}</Text>
            <Description>
                {description}
            </Description>
        </CardContent>
    )
}

export default CardCourse

const CardContent = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 8px 12px -6px rgba(35, 21, 91, 0.05), 0px 14px 64px -4px rgba(35, 21, 91, 0.05);
    border-radius: 24px;
    width: calc(100% - 3rem);
    height: calc(180px - 3rem);
    padding: 1.5rem;
    display: flex;
    flex-direction: column;
    cursor: pointer;
    gap: 8px;
`

const Description = styled.div`
    height: 100px;
    overflow-y: auto;
    font-size: 14px;
`