import { createBrowserRouter } from "react-router-dom";
import Login from "../pages/Login";
import Landing from "../pages/Landing";
import Custom from "../pages/Custom";

export const router = createBrowserRouter([
    {
        path: "/admin",
        element: <Login />,
    },
    {
        path: "/",
        element: <Landing />,
    },
    {
        path: "/custom",
        element: <Custom />,
    },
]);