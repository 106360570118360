import { useState } from "react"

const useAuth = () => {

    const [login, setLogin] = useState(localStorage.getItem('isLogin') || false)

    const handleLoginStorage = (value) => {
        localStorage.setItem('isLogin', value)
        setLogin(value)
    }

    return { isLogin: login === 'true' ? true : false, handleLoginStorage }
}

export default useAuth