import React from "react";
import Navbar from "../components/landing/navbar/Navbar";
import Banner from "../components/landing/banner/Banner";
import Investigation from "../components/landing/investigation/Investigation";
import Laboratory from "../components/landing/laboratory/Laboratory";
import JoinInvestigation from "../components/landing/join/JoinInvestigation";
import Press from "../components/landing/press/Press";
import Courses from "../components/landing/courses/Courses";

const Landing = () => {
    return (
        <>
            <Navbar />
            <Banner />
            <Investigation />
            <Laboratory />
            <JoinInvestigation />
            <Courses/>
            <Press/>
        </>
    );
};

export default Landing;
