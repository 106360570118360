import React from 'react'
import styled from 'styled-components'
import Text from '../../../common/Text'

const Item = () => {
    return (
        <Content id='item'>
            <Circle />
            <Text color={"#23155B"} fontSize={"18px"} fontWeight={"bold"}>Nombre de persona</Text>
            <Text fontSize={"14px"}>Título o rol en la investigación</Text>
        </Content>
    )
}

export default Item

const Content = styled.div`
    width: 250px !important;
    display: flex;
    flex-direction: column;
    gap: 8px;
    justify-content: center;
    align-items: center;
`

const Circle = styled.div`
    width: 200px;
    height: 200px;
    background-color: #D9D9D9;
    border-radius: 50%;
`