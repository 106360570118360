import { RouterProvider } from 'react-router-dom';
import { LanguageProvider } from './providers/Language';
import { router } from './routes/routes';
import { ThemeProvider } from '@mui/material';
import { theme } from './theme/theme';

function App() {



  return (
    <ThemeProvider theme={theme}>
      <LanguageProvider>
        <RouterProvider router={router} />
      </LanguageProvider>
    </ThemeProvider>
  );
}

export default App;
