import React, { useState } from 'react'
import styled from 'styled-components'
import Text from '../components/common/Text'
import { Button, TextField } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import useAuth from '../hooks/useAuth'
import logo from "../assets/images/Logo.svg";

const Login = () => {

    const navigate = useNavigate()
    const { isLogin, handleLoginStorage } = useAuth()
    const [inputs, setInputs] = useState({
        user: '',
        password: ''
    })

    const handleChange = (e) => {
        const { value, name } = e.target
        setInputs({...inputs, [name] : value})
    }

    const handleLogin = () => {
        if (inputs.user === 'admin' && inputs.password === 'admin123') {
            handleLoginStorage(true)
            navigate('../custom')
        }
        else {
            alert('Incorrect credentials!')
        }
    }

    if (!!isLogin) {
        window.open('../custom', '_self')
    }

    return (
        <LoginContent>
            <Card>
                <img alt='logo' src={logo} />
                <Text>Login</Text>
                <TextField onChange={handleChange} name='user' placeholder='User' fullWidth size='small'/>
                <TextField onChange={handleChange} type='password' name='password' placeholder='Password' fullWidth size='small' />
                <Button disabled={!inputs.user || !inputs.password} onClick={handleLogin} fullWidth color='primary' variant='contained'>Login</Button>
            </Card>
        </LoginContent>
    )
}

export default Login

const LoginContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
`

const Card = styled.div`
    width: 300px;
    height: auto;
    border-radius: 5px;
    border: 1px solid #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 12px;
    padding: 1rem;
`