import React from 'react'
import styled from 'styled-components'
import image from '../../../assets/images/image1.png'
import Text from '../../common/Text'
import useLanguage from '../../../hooks/useLanguage'
import { Button } from '@mui/material'
import EditSection from '../../common/EditSection'
import EditButton from '../../common/EditButton'

const Investigation = (props) => {

    const { isEdit = false } = props

    const { data } = useLanguage()

    if (!data) {
        return <div />
    }

    const titleArray = data.investigation.title.split(" ")
    const title = titleArray.slice(0, titleArray.length - 1).join(" ")
    const lastWord = titleArray.slice(titleArray.length - 1, titleArray.length)

    return (
        <Content id="investigation">
            <ImageContent src={image} />
            <Information>
                <Text id="title1" fontSize={"42px"} color={"#23155B"} fontWeight={"bold"}>{title}</Text>
                <Text id="title2" fontSize={"42px"} color={"#8053FF"} fontWeight={"bold"}>{lastWord}</Text>
                <EditSection>
                    <div id="desc" dangerouslySetInnerHTML={{ __html: data.investigation.description }} />
                    {isEdit && <EditButton route={"investigation.description"} label="Descripcion de la investigacion" value={data.investigation.description} />}
                </EditSection>
                <br />
                <Button variant='outlined' color='primary'>{data.investigation.button}</Button>
            </Information>
        </Content>
    )
}

export default Investigation

const Content = styled.section`
    position: relative;
    height: 1080px;
    width: 100%;
    background-color: white;
    z-index: 2;
    width: calc(100% - 400px);
    padding: 0 200px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (max-width: 768px) {
        padding: 1rem;
        width: calc(100% - 2rem);
    }
`

const ImageContent = styled.img`
    position: absolute;
    left: -170px;
    border-radius: 100px;
    /* width: 768px; */
    height: 1080px;
    top: -50px;
    @media (max-width: 768px) {
        display: none;
        left: 0;
    }
`

const Information = styled.div`
    width: 65%;
    @media (max-width: 768px) {
        width: 100%;
        text-align: center;
        #title1, #title2{
            font-size: 36px;
            text-align: center;
        }
    }
    /* padding-top: 80px; */
`