import { Button, IconButton, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import Popover from '@mui/material/Popover';
import styled from 'styled-components';
import Text from './Text';
import useLanguage from '../../hooks/useLanguage';

const EditButton = (props) => {

    const { value = '', label = "", route, color } = props

    const [anchorEl, setAnchorEl] = useState(null);
    const [valueInput, setValue] = useState(value)
    const { currentLan, handleSetItemLanguage, loading } = useLanguage()

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    useEffect(() => {
        !open && setValue(value)
    }, [open, currentLan])


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleChange = e => {
        setValue(e.target.value)
    }

    const handleSubmit = () => {
        handleSetItemLanguage(valueInput, route)
        handleClose()
    }

    return (
        <div>
            <IconButton onClick={handleClick} color={color || 'primary'}><EditIcon /></IconButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
            >
                <Content>
                    <Text fontSize={"20px"} color={"#8053ff"}>Editar contenido</Text>
                    <br />
                    <TextField onChange={handleChange} multiline fullWidth label={label} value={valueInput} autoFocus={true} />
                    <Buttons>
                        {!loading && <Button onClick={handleClose}>Cancelar</Button>}
                        <Button disabled={loading} onClick={handleSubmit} variant='contained' color='primary'>{!loading ? 'Aplicar' : 'Guardando'}</Button>
                    </Buttons>
                </Content>
            </Popover>
        </div>
    )
}

export default EditButton

const Content = styled.div`
    width:  800px;
    height: auto;
    padding: 1rem;
`

const Buttons = styled.div`
    border-top: 1px solid #8053ff22;
    margin-top: 1rem;
    padding-top: 1rem;
    display: flex;
    justify-content: flex-end;
    gap: 6px;
`